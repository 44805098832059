import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import MenuWrapper, { HeaderDropDown } from "./style";
import {
  Menu,
  Dropdown,
  Select,
  Modal,
  Divider,
  Space,
  Typography,
  Badge,
} from "antd";
import LoginPage from "../LoginPage";

import Logo from "../../assets/images/OpenCase transparent.png";
import Cart from "../../assets/images/cart.png";
import userAvatar from "../../assets/images/user.png";
// import History from "../../assets/images/history.png";
import SavedSearch from "../../assets/images/savedSearch.png";
import VerifyEmailPopup from "../Profile/VerifyEmailPopup";
import ProjectsForm from "../Profile/ProjectsForm";
import { getCartIds } from "../../helpers/api";

const { Option } = Select;

const logouAction = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("selectedProject");
  localStorage.removeItem("email");

  window.location.href = "/";
};

const menu = () => (
  <Menu
    style={{
      padding: 10,
    }}
  >
    <Menu.Item>
      <Link to="/profile">Profile</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/cart">Cart</Link>
    </Menu.Item>
    <Menu.Item onClick={logouAction}>Logout</Menu.Item>
  </Menu>
);

const urls = [
  "/",
  "/signup",
  "/reset_password",
  "/verify_email",
  "/verify_email/",
  "/auths/",
  "/subscriptions",
  "/explore/PDL1_NSCLC",
  "/reset_password",
];

const TopMenu = ({
  setSelectedProject,
  selectedProject,
  projects,
  updateProjects,
}) => {
  const [projectsModalVisible, setProjectsModalVisible] = useState(false);
  const location = useLocation();
  const isLoginPopup = JSON.parse(localStorage.getItem("loginPopup"));
  const isVerifyEmailPopup = JSON.parse(
    localStorage.getItem("VerifyEmailPopup")
  );
  const [cartCount, setCartCount] = useState(undefined);

  const showMenu = urls.includes(location.pathname);

  // get the projects
  useEffect(() => {
    if (!showMenu) {
      updateProjects().then((res) => {
        if (!localStorage.getItem("selectedProject")) {
          handleChange(res?.data[0]?.id);
        }
        getCartIds(res?.data[0]?.id).then((res) => {
          setCartCount(res?.data?.case_report_ids?.length);
        });
      });
    }
  }, [location.pathname, isLoginPopup, isVerifyEmailPopup]);

  const handleChange = (value) => {
    if (value) {
      localStorage.setItem("selectedProject", +value);
      setSelectedProject(+value);
    }
  };

  const handleServiceChange = (value) => {
    if (value) {
      localStorage.setItem("selectedService", +value);
      setSelectedProject(+value);
    }
  };

  const toggleProjectsModal = () => {
    setProjectsModalVisible(!projectsModalVisible);
  };

  return location.pathname === "/" ? (
    ""
  ) : (
    <MenuWrapper>
      <Modal
        visible={projectsModalVisible}
        closable={true}
        onCancel={() => setProjectsModalVisible(false)}
        style={{ background: "transparent" }}
        footer={false}
      >
        <ProjectsForm projects={projects} getProjects={updateProjects} />
      </Modal>

      <Link to="/search" className="logoWrapper">
        <img src={Logo} className="logo" alt="logo" />
      </Link>

      {isLoginPopup && !urls.includes(location.pathname) && (
        <Modal
          visible={isLoginPopup}
          footer={false}
          closable={false}
          width="100vw"
        >
          <LoginPage popup={true} />
        </Modal>
      )}
      {isVerifyEmailPopup && (
        <Modal visible={isVerifyEmailPopup} footer={false} closable={false}>
          <VerifyEmailPopup />
        </Modal>
      )}
      {showMenu ? null : (
        <>
          <Dropdown
            overlay={menu}
            placement="bottomRight"
            arrow={{ pointAtCenter: true }}
          >
            <img src={userAvatar} className="avatar" alt="user" />
          </Dropdown>

          <Link to="/cart">
            <Badge count={cartCount} className="cartBadge" color="blue">
              <img src={Cart} className="cart" alt="cart" />
            </Badge>
          </Link>
          <Link to="#">
            {/* <img src={History} className="cart" alt="cart" /> */}
            <img
              src={SavedSearch}
              className="savedSearch"
              alt="savedSearch"
              title="Saved Searches (Coming soon)"
            />
          </Link>
          <HeaderDropDown>
            <span style={{ marginRight: 10 }}>Project :</span>
            <Select
              style={{ width: 200 }}
              placeholder="Select a project"
              onChange={handleChange}
              value={
                selectedProject ||
                +localStorage.getItem("selectedProject") ||
                null
              }
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space
                    align="center"
                    style={{
                      padding: "0 8px 4px",
                      gap: 0,
                      textAlign: "center",
                      display: "block",
                    }}
                  >
                    <Typography.Link
                      onClick={toggleProjectsModal}
                      style={{ whiteSpace: "nowrap", textAlign: "center" }}
                    >
                      Add Project
                    </Typography.Link>
                  </Space>
                </>
              )}
            >
              {projects.map((e) => (
                <Option value={+e.id} key={e.id}>
                  {e.description}
                </Option>
              ))}
            </Select>
          </HeaderDropDown>
          <HeaderDropDown>
            <span style={{ marginRight: 10 }}>Services :</span>
            <Select
              style={{ width: 250 }}
              placeholder="Select a service"
              onChange={handleServiceChange}
              value={window.location.pathname === "/search" ? "CE" : null}
            >
              <Select.Option value="CE">
                <Link
                  to="/search"
                  style={{
                    color: "#000",
                  }}
                >
                  Comparative Effectiveness
                </Link>
              </Select.Option>
              <Select.Option value="OL" disabled>
                Off-Label (Coming Soon)
              </Select.Option>
              <Select.Option value="PV" disabled>
                Pharmacovigilance (Coming Soon)
              </Select.Option>
            </Select>
          </HeaderDropDown>
        </>
      )}
    </MenuWrapper>
  );
};

export default TopMenu;

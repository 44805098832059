import styled from "styled-components";

export const CaracteristicsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  div {
    width: 50%;
    padding: 20px;
  }

  .seperator {
    display: block;
    height: 15px;
  }
`;

export const SearchResultWrapper = styled.div`
  width: 100%;
  padding: 5px;
  padding-right: 30px;
  overflow: auto;
  height: calc(100vh - 165px);
  .ant-table {
    background: transparent;
  }

  .resultRow {
    display: flex;
    background: #fff;
    border-radius: 15px;

    box-shadow: 0px 0px 10px #2a3a4e;
    margin-bottom: 15px;
    align-items: center;
    transition: box-shadow 0.5s ease;

    > td:first-child {
      border-right: 1px solid #54749d;
      flex-grow: 0;
      width: 35px;
    }

    > td:nth-child(2) {
      flex-grow: 0;
      width: 80px;
      text-align: center;
    }

    > td:last-child {
      flex-grow: 0;
      width: 250px;
    }

    &:hover {
      box-shadow: 0px 0px 20px 2px #2a3a4e;
    }

    &.disabled-row {
      pointer-events: none;
      opacity: 0.7;
    }
  }

  .ant-table-tbody > tr > td {
    border-bottom: none;
    padding: 10px;
    width: calc(17% - 40px)
  }

  
`;

export const TableCell = styled.div`
  height: 140px;
  cursor: pointer; 

  div {
    max-width: 100% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  h3 {
    color: #3a506b;
  }
`;

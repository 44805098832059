import { Wrapper } from "./style";

const Subscriptions = () => {
  return (
    <div
      style={{
        height: "calc(100vh - 100px)",
        display: "flex",
        margin: "auto",
        alignItems: "center",
        justifyContent: "space-evenly",
        flexDirection: "row",
        float: "left",
        width: "100%",
        padding: "0 100px"
      }}
    >
      <Wrapper>
        <h1>PharmacoVigilance</h1>
        <div>
          Get alerted about safety signals that could affect regulatory labels
          for novel oncology therapies. The OpenCase patient data contains rich
          information on drug-related adverse events with causation adjudicated
          by clinicians. The subscription includes monthly updates with
          standardized and manually curated data output of detected safety
          signals including data elements such as grade of adverse events,
          resolution of adverse events, comorbidities, concomitant therapies,
          and underlying cancer information and patient demographics.
          <span>Price:</span>
          <p>
            $5,000/monthly (up to 5 therapies monitored). $750 per additional
            therapy monitored
          </p>
        </div>
      </Wrapper>
      <Wrapper>
        <h1>Off-Label Oncology Drug Surveillance</h1>
        <div>
          Get alerted about novel off-label use of approved novel oncology
          therapies. The OpenCase database includes several patient journeys
          where novel cancer therapies were used outside of the indication on
          their label indication (i.e. off-label). The subscription includes
          monthly updates with standardized and manually curated data output of
          efficacy and safety data during the course of off-label use and
          follow-up, treatment and cancer history, comorbidities, concomitant
          therapies, as well as patient demographics.
          <span>Price:</span>
          <p>
            $5,000/monthly (up to 5 therapies monitored). $750 per additional
            therapy monitored
          </p>
        </div>
      </Wrapper>
      <Wrapper>
        <h1>Comparative Effectiveness Sample Size Top-Up</h1>
        <div>
          Comparative effectiveness studies sourcing patient medical records are
          limited by data completeness and availability. A combination of
          multiple data sources is widely accepted for augmenting study
          precision. OpenCase provides easy searches, views, and selections of
          patient journeys, and allows quick downloads of analysis ready
          datasets for study eligible patients. Data sets include patient
          demographics, onco-biomarkers and genomics, treatments, outcomes data
          <span>Price:</span>
          <p>
            $5,000/quarter. Credits for analysis-ready data for ten patients
            included in subscription. 500$ per additional patient. Unused
            credits automatically carry over.
          </p>
        </div>
      </Wrapper>
    </div>
  );
};

export default Subscriptions;

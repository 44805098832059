// Styles
import { useEffect, useState } from "react";
import { Alert, Spin } from "antd";
import { verifyEmail } from "../../helpers/api";

const VerifyEmail = () => {
  const [loading, setLoading] = useState(true);
  const [msg, setMsg] = useState("");
  const [err, setErr] = useState(null);

  useEffect(() => {
    const searchParams = window.location.search;
    verifyEmail(searchParams)
      .then((res) => {
        setLoading(false);
        setMsg(
          "Email verified! You will be redirected automatically to our login page in a moment ..."
        );
        setErr("");
        setTimeout(() => {
          window.location.href = "/";
        }, 3000);
      })
      .catch((e) => {
        setLoading(false);
        setErr("oops!, something went wrong");
        setMsg("");
      });
  }, []);

  return (
    <div style={{ textAlign: "center", marginTop: 100 }}>
      {loading ? (
        <Alert
          style={{ width: 600, margin: "auto" }}
          type="info"
          message={"Verifying your email .."}
          description={<Spin size="medium" spinning={true} />}
        />
      ) : err ? (
        <Alert
          style={{ width: 600, margin: "auto" }}
          type="error"
          message={err}
          description={msg}
        />
      ) : (
        <Alert
          style={{ width: 600, margin: "auto" }}
          type="success"
          message={"Email verified!"}
          description={msg}
        />
      )}
    </div>
  );
};

export default VerifyEmail;

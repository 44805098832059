import { Link } from "react-router-dom";
import Wrapper from "./style";

const urls = ["/", "/login", "/signup", "/subscriptions", "/explore/PDL1_NSCLC"]
const SideBar = () => {
  return !urls.includes(window.location.pathname) ? (
    <Wrapper>
      <Link to="/search">Comparative Effectiveness</Link>
      <Link to="#" disabled style={{ opacity: 0.6}}>
        Off-Label (Coming Soon)
      </Link>
      <Link to="#" disabled style={{ opacity: 0.6}}>
        Pharmacovigilance (Coming Soon)
      </Link>
    </Wrapper>
  ) : null;
};

export default SideBar;

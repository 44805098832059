import { useEffect, useState } from "react";
import { AppWrapper } from "../../components/shared/Elements";
import Histogram from "./Charts/Histogram";
import { DashboardItem, Quadrant, Wrapper } from "./style";
import * as Papa from "papaparse";
import { Modal, Select, Tabs } from "antd";
import codes from "./countryCodes";
import romanNumbers from "./romanNumbers";
import { ZoomInOutlined } from "@ant-design/icons";

const csvFilePath = "/data.csv";
const csvFilePath2 = "/data_2_new.csv";
const csvFilePath3 = "/data_3.csv";
const csvFilePath4 = "/data_4.csv";
const csvFilePath5 = "/data_5.csv";
const csvFilePath6 = "/data_6.csv";
const csvFilePath7 = "/data_7_new.csv";
const csvFilePath8 = "/data_8.csv";

const shades = [
  "007eff",
  "1a8bff",
  "3398ff",
  "4da5ff",
  "66b2ff",
  "80bfff",
  "99cbff",
  "b3d8ff",
  "cce5ff",
  "e6f2ff",
];

const palette = [
  "#8FBBDA",
  "#84B4D6",
  "#79ADD2",
  "#6DA7CE",
  "#62A0CB",
  "#5799C7",
  "#4C92C3",
  "#418BBF",
  "#3585BC",
  "#2A7EB8",
  "#1D71AB",
  "#1C6BA2",
  "#1A6599",
  "#195F90",
  "#175987",
  "#16537E",
  "#144D75",
  "#13476C",
  "#114163",
  "#103C5A",
  "#0E3651",
  "#fff",
];
const Dashboard = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [quadrantHeight, setQuadrantHeight] = useState(0);

  const [smokingStatus, setSmokingStatus] = useState([]);
  const [ageSex, setAgeSex] = useState([]);
  const [cancerDiagnosis, setCancerDiagnosis] = useState([]);
  const [stage, setStage] = useState([]);
  const [cancerType, setCancerType] = useState([]);
  const [onco, setOnco] = useState([]);
  const [curveData, setCurveData] = useState([]);
  const [osData, setOsData] = useState([]);
  const [adverseEvents, setAdverseEvents] = useState([]);
  const [treatmentNames, setTreatmentNames] = useState([]);
  const [country, setCountry] = useState("");
  const [countryName, setCountryName] = useState(undefined);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    Promise.all([
      fetch(csvFilePath).then((res) => res.text()),
      fetch(csvFilePath2).then((res) => res.text()),
      fetch(csvFilePath3).then((res) => res.text()),
      fetch(csvFilePath4).then((res) => res.text()),
      fetch(csvFilePath5).then((res) => res.text()),
      fetch(csvFilePath6).then((res) => res.text()),
      fetch(csvFilePath7).then((res) => res.text()),
      fetch(csvFilePath8).then((res) => res.text()),
    ])
      .then((result) => {
        const x = result.map((res) => {
          const parsed = Papa.parse(res);

          parsed.data?.shift();
          return parsed.data;
        });

        return x;
      })
      .then((data) => {
        const [withCountry, ...rest] = data;
        return [
          withCountry,
          ...rest.map((csv) => {
            return csv.map((e) => {
              const country = withCountry.find((x) => {
                return x[0] === e[0] || x[0] === `lung_${e[0]}`})?.[5];
              return [...e, country];
            });
          }),
        ];
      })
      .then((data) => {
        // Age & Sex
        const ageSex = data[0]
          .filter((e) => (countryName ? e[5] === countryName : e[5]))
          .reduce(
            (acc, curr) => {
              const age = curr[1];
              if (age <= 18) {
                if (curr[2] === "Male") acc[0].y[0] = acc[0].y[0] + 1;
                else if (curr[2] === "Female") acc[1].y[0] = acc[1].y[0] + 1;
              }

              if (age > 18 && age <= 30) {
                if (curr[2] === "Male") acc[0].y[1] = acc[0].y[1] + 1;
                else if (curr[2] === "Female") acc[1].y[1] = acc[1].y[1] + 1;
              }

              if (age > 30 && age <= 40) {
                if (curr[2] === "Male") acc[0].y[2] = acc[0].y[2] + 1;
                else if (curr[2] === "Female") acc[1].y[2] = acc[1].y[2] + 1;
              }

              if (age > 40 && age <= 50) {
                if (curr[2] === "Male") acc[0].y[3] = acc[0].y[3] + 1;
                else if (curr[2] === "Female") acc[1].y[3] = acc[1].y[3] + 1;
              }

              if (age > 50 && age <= 65) {
                if (curr[2] === "Male") acc[0].y[4] = acc[0].y[4] + 1;
                else if (curr[2] === "Female") acc[1].y[4] = acc[1].y[4] + 1;
              }

              if (age > 65) {
                if (curr[2] === "Male") acc[0].y[5] = acc[0].y[5] + 1;
                else if (curr[2] === "Female") acc[1].y[5] = acc[1].y[5] + 1;
              }
              return acc;
            },
            [
              {
                histfunc: "sum",
                y: [0, 0, 0, 0, 0, 0],
                x: ["<18", "19-30", "31-40", "41-50", "51-65", ">65"],
                type: "histogram",
                name: "Male",
              },
              {
                histfunc: "sum",
                y: [0, 0, 0, 0, 0, 0],
                x: ["<18", "19-30", "31-40", "41-50", "51-65", ">65"],
                type: "histogram",
                name: "Female",
                marker: {
                  color: "#79ADD2",
                },
              },
            ]
          );

        setAgeSex(ageSex);

        // Smoking Status
        const smokingStatus = data[0]
          .filter((e) => (countryName ? e[5] === countryName : e[5]))
          .reduce(
            (acc, curr) => {
              if (curr[3]) {
                const ndx = acc.labels.findIndex((e) => e === curr[3]);
                if (ndx > -1) {
                  acc.values[ndx] = acc.values[ndx] + 1;
                } else {
                  acc.values.push(1);
                  acc.labels.push(curr[3]);
                }
              }
              return acc;
            },
            {
              values: [],
              labels: [],
              type: "pie",
            }
          );
        setSmokingStatus([smokingStatus]);

        // Cancer Diagnosis
        const cancerDiagnosis = data[1]
          .filter((e) => (countryName ? e[17] === countryName : e[17]))
          .reduce(
            (acc, curr) => {
              if (curr[3]) {
                const ndx = acc.labels.findIndex((e) => e === curr[3]);
                if (ndx > -1) {
                  acc.values[ndx] = acc.values[ndx] + 1;
                } else {
                  acc.values.push(1);
                  acc.labels.push(curr[3]);
                }
              }
              return acc;
            },
            {
              values: [],
              labels: [],
              type: "pie",
            }
          );

        const x = {
          x: cancerDiagnosis.labels,
          y: cancerDiagnosis.values,
          type: "bar",
        };
        setCancerType(x);
        setCancerDiagnosis([cancerDiagnosis]);

        // Stage

        const stage = data[1]
          .filter((e) => (countryName ? e[17] === countryName : e[17]))
          .reduce(
            (acc, curr) => {
              if (curr[6] !== "NA") {
                const stage = curr[17];
                const metastatic = curr[18];

                const ndx = acc[0].x.findIndex((e) => e === stage);
                const ndx2 = acc[1].x.findIndex((e) => e === metastatic);

                if (ndx > -1) {
                  acc[0].y[ndx] = acc[0].y[ndx] + 1;
                } else {
                  // acc[0].x.push(romanNumbers[stage.trim()] || stage);
                  acc[0].x.push(stage);
                  acc[0].y.push(0);
                }

                if (ndx2 > -1) {
                  acc[1].y[ndx] = acc[1].y[ndx] + 1;
                } else {
                  // acc[1].x.push(romanNumbers[stage.trim()] || stage);
                  acc[1].x.push(stage);
                  acc[1].y.push(0);
                }
              }
              return acc;
            },
            [
              {
                histfunc: "sum",
                y: [],
                x: [],
                type: "histogram",
                name: "Local",
              },
              {
                histfunc: "count",
                y: [],
                x: [],
                type: "histogram",
                name: "Metastatic",
                marker: {
                  color: "#79ADD2",
                },
              },
            ]
          );

        setStage(stage);

        // Mutations
        const onco = data[2]
          .filter((e) => (countryName ? e[14] === countryName : e[14]))
          .reduce(
            (acc, curr) => {
              const [, , ALK, BRAF, EGRF, KRAS, , , , ROS] = curr;
              // ALK
              if (ALK === "Positive") {
                acc[0].y[0] = acc[0].y[0] + 1;
              } else if (ALK === "Negative") {
                acc[1].y[0] = acc[1].y[0] + 1;
              } else if (ALK === "NA") {
                // acc[2].y[0] = acc[2].y[0] + 1;
              }

              // BRAF
              if (BRAF === "Positive") {
                acc[0].y[1] = acc[0].y[1] + 1;
              } else if (BRAF === "Negative") {
                acc[1].y[1] = acc[1].y[1] + 1;
              } else if (BRAF === "NA") {
                // acc[2].y[1] = acc[2].y[1] + 1;
              }
              //EGRF
              if (EGRF === "Positive") {
                acc[0].y[2] = acc[0].y[2] + 1;
              } else if (EGRF === "Negative") {
                acc[1].y[2] = acc[1].y[2] + 1;
              } else if (EGRF === "NA") {
                // acc[2].y[2] = acc[2].y[2] + 1;
              }
              //KRAS
              if (KRAS === "Positive") {
                acc[0].y[3] = acc[0].y[3] + 1;
              } else if (KRAS === "Negative") {
                acc[1].y[3] = acc[1].y[3] + 1;
              } else if (KRAS === "NA") {
                // acc[2].y[3] = acc[2].y[3] + 1;
              }
              // ROS
              if (ROS === "Positive") {
                acc[0].y[4] = acc[0].y[4] + 1;
              } else if (ALK === "Negative") {
                acc[1].y[4] = acc[1].y[4] + 1;
              } else if (ALK === "NA") {
                // acc[2].y[4] = acc[2].y[4] + 1;
              }

              return acc;
            },
            [
              {
                histfunc: "sum",
                x: [
                  "ALK status",
                  "BRAF exons",
                  "EGFR status",
                  "KRAS status",
                  "ROS-1/c-ROS",
                ],
                y: [0, 0, 0, 0, 0],
                type: "histogram",
                name: "Positive",
                marker: {
                  color: "#13476C",
                },
              },
              {
                histfunc: "sum",
                x: [
                  "ALK status",
                  "BRAF exons",
                  "EGFR status",
                  "KRAS status",
                  "ROS-1/c-ROS",
                ],
                y: [0, 0, 0, 0, 0],
                type: "histogram",
                name: "Negative",
                marker: {
                  color: "#1D71AB",
                },
              },
              // {
              //   histfunc: "sum",
              //   x: [
              //     "ALK status",
              //     "BRAF exons",
              //     "EGFR status",
              //     "KRAS status",
              //     "ROS-1/c-ROS",
              //   ],
              //   y: [0, 0, 0, 0, 0],
              //   type: "histogram",
              //   name: "N/A",
              //   marker: {
              //     color: "#79ADD2",
              //   },
              // },
            ]
          );

        setOnco(onco);

        const treatmentNames = data[3]
          .filter((e) => (countryName ? e[31] === countryName : e[31]))
          .map((e) => e[5]);
        setTreatmentNames(treatmentNames);

        // Curves
        const curve = data[6]
          .filter((e) => (countryName ? e[18] === countryName : e[18]))
          .reduce(
            (acc, curr) => {
              if (curr[6] && curr[10]) {
                const x = curr[6];
                const y = curr[10];
                acc.x.push(x);
                acc.y.push(y);
              }
              return acc;
            },
            {
              type: "scatter",
              x: [],
              y: [],
              line: { shape: "hvh" },
              name: "PD-L1 inhibitors"
            }
          );

        // setCurveData([curve]);

        // OS ( temp )
        // remove the last 2
        data[7].pop();
        data[7].pop();
        const osData = data[7]
          // .filter((e) => (countryName ? e[12] === countryName : e[12]))
          .reduce(
            (acc, curr) => {
              if (curr[8] && curr[11]) {
                const x = curr[8];
                const y = curr[11];
                acc.x.push(x);
                acc.y.push(y);
              }
              return acc;
            },
            {
              type: "scatter",
              x: [],
              y: [],
              line: { shape: "hvh" },
              name: "Chemo"
            }
          );

        osData.marker = {
          color: "rgb(17, 65, 99)",
        };
        setCurveData([curve, osData]);
        // setOsData([osData]);

        // Adverse events
        const adverseEvents = data[5]
          .filter((e) => (countryName ? e[14] === countryName : e[14]))
          .reduce((acc, curr) => {
            if (curr[3]) {
              const ndx = acc.findIndex((e) => e.label === curr[3]);
              if (ndx > -1) {
                acc[ndx].count = acc[ndx].count + 1;
              } else {
                acc.push({ label: curr[3], count: 1 });
              }
            }
            return acc;
          }, [])
          .sort((a, b) => b.count - a.count);

        const total = adverseEvents.length - 20;

        const events = adverseEvents.slice(0, 20).reduce(
          (acc, curr) => {
            acc.values.push(curr.count);
            acc.labels.push(curr.label);
            return acc;
          },
          {
            values: [total],
            labels: ["Other"],
            type: "pie",
            hole: 0.4,
            textinfo: "label+value",
            textposition: windowWidth < 1670 ? "inside" : "outside",
            marker: {
              colors: palette.reverse(),
            },
          }
        );

        setAdverseEvents([events]);
      });

    const resizeWindow = () => {
      const width = document.querySelector(".quadrant").offsetWidth;
      const height = window.innerHeight * 0.4;
      setQuadrantHeight(height);
      setWindowWidth(width - 30);
    };
    resizeWindow();

    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, [country]);

  const closeModal = () => {
    setModalOpen(undefined);
  };

  const charts = {
    worldMap: (zoom) => (
      <div style={{ height: 480, display: "flex" }}>
        <iframe
          style={{
            position: "absolute",
            top: 10,
            left: 0,
            width: "100%",
            height: 480,
            border: "none",
          }}
          src={`/worldMap/choroplethMap.html?scale=350&country=${country}`}
        ></iframe>
      </div>
    ),
    pfs: (zoom) => (
      <Histogram
        data={curveData}
        width={windowWidth}
        layoutOptions={{
          yaxis: {
            range: [0, 1.05],
          },
        }}
        zoom={zoom}
      />
    ),
    os: (zoom) => (
      <Histogram
        data={osData}
        width={windowWidth}
        layoutOptions={{
          yaxis: {
            range: [0, 1.05],
          },
        }}
      />
    ),
    ageSex: (zoom) => (
      <Histogram data={ageSex} width={windowWidth} zoom={zoom} />
    ),
    mutation: (zoom) => (
      <Histogram data={onco} width={windowWidth} zoom={zoom} />
    ),
    stage: (zoom) => <Histogram data={stage} width={windowWidth} zoom={zoom} />,
    adverseEvents: (zoom) => (
      <Histogram
        data={adverseEvents}
        width={windowWidth}
        mb={window.innerWidth > 1700 ? 50 : 0}
        title={window.innerWidth > 1700 ? " " : undefined}
        zoom={zoom}
      />
    ),
  };
  return (
    <Wrapper>
      <Quadrant className="quadrant">
        <DashboardItem
          style={{
            position: "relative",
          }}
        >
          <Select
            onChange={(value) => {
              setCountry(value);
              const code = codes.find((e) => e.code === value)?.country;
              setCountryName(code);
            }}
            placeholder="Select country"
            className="countryFilter"
            showSearch={true}
          >
            <Select.Option>all</Select.Option>
            {codes.map((e) => (
              <Select.Option value={e.code}>{e.country}</Select.Option>
            ))}
          </Select>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: 1,
                label: (
                  <span>
                    Global Data
                    <ZoomInOutlined
                      onClick={() => {
                        setModalOpen("worldMap");
                      }}
                      style={{
                        color: "#0e3651",
                        marginLeft: 10,
                        fontSize: 14,
                      }}
                    />
                  </span>
                ),
                children: charts["worldMap"](),
              },
            ]}
          />
        </DashboardItem>
      </Quadrant>

      <Quadrant className="quadrant">
        <DashboardItem>
          {/* <Select
            onChange={(value) => {
              // setCountry(value);
              // const code = codes.find((e) => e.code === value)?.country;
              // setCountryName(code);
            }}
            placeholder="Select treatment"
            className="countryFilter"
            showSearch={true}
          >
            <Select.Option>all</Select.Option>

            <Select.Option value={"PD-L1"}>PD-L1 inhibitors</Select.Option>
            <Select.Option value={"Chemotherapy"}>
              Chemotherapy (platinum based chemo)
            </Select.Option>
          </Select> */}
          <Tabs
            defaultActiveKey="1"
            items={[
              // {
              //   key: 1,
              //   label: "Survival curve",
              //   children: (
              //     <Histogram
              //       data={curveData}
              //       width={windowWidth}
              //       layoutOptions={{
              //         yaxis: {
              //           range: [0, 1.05],
              //         },
              //       }}
              //     />
              //   ),
              // },
              {
                key: 2,
                label: (
                  <span>
                    Progression Free Survival
                    <ZoomInOutlined
                      onClick={() => {
                        setModalOpen("pfs");
                      }}
                      style={{
                        color: "#0e3651",
                        marginLeft: 10,
                        fontSize: 14,
                      }}
                    />
                  </span>
                ),
                children: charts["pfs"](),
              },
              // {
              //   key: 3,
              //   label: (
              //     <span>
              //       Overall Survival
              //       <ZoomInOutlined
              //         onClick={() => {
              //           setModalOpen("os");
              //         }}
              //         style={{
              //           color: "#0e3651",
              //           marginLeft: 10,
              //           fontSize: 14,
              //         }}
              //       />
              //     </span>
              //   ),
              //   children: charts["os"](),
              // },
            ]}
          />
        </DashboardItem>

        {/* <DashboardItem
          style={{ height: 480, display: "flex", alignItems: "center" }}
        >
          <Pie title="Smoking Status" data={smokingStatus} />
        </DashboardItem>

        <DashboardItem style={{ width: 600 }}>
          <Histogram
            data={[
              {
                histfunc: "sum",
                x: treatmentNames,
                type: "histogram",
              },
            ]}
            title="Treatment Name"
            width={600}
            mb={150}
          />
        </DashboardItem>
        <DashboardItem
          style={{
            width: 600,
            height: 480,
            float: "right",
          }}
        >
          <iframe
            src="/Chart/Bar chart_vertical.html"
            style={{ overflow: "hidden" }}
          ></iframe>
        </DashboardItem> */}
      </Quadrant>
      <Quadrant className="quadrant">
        <DashboardItem>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: 1,
                label: (
                  <span>
                    Age - Sex
                    <ZoomInOutlined
                      onClick={() => {
                        setModalOpen("ageSex");
                      }}
                      style={{
                        color: "#0e3651",
                        marginLeft: 10,
                        fontSize: 14,
                      }}
                    />
                  </span>
                ),
                children: charts["ageSex"](),
              },
              {
                key: 2,
                label: (
                  <span>
                    Stage
                    <ZoomInOutlined
                      onClick={() => {
                        setModalOpen("stage");
                      }}
                      style={{
                        color: "#0e3651",
                        marginLeft: 10,
                        fontSize: 14,
                      }}
                    />
                  </span>
                ),
                children: charts["stage"](),
              },
              // {
              //   key: 3,
              //   label: "Cancer Type",
              //   children: (
              //     <Histogram
              //       data={[cancerType]}
              //       title="Cancer Type"
              //       width="800"
              //       mb={120}
              //     />
              //   ),
              // },
              {
                key: 4,
                label: (
                  <span>
                    Mutations
                    <ZoomInOutlined
                      onClick={() => {
                        setModalOpen("mutation");
                      }}
                      style={{
                        color: "#0e3651",
                        marginLeft: 10,
                        fontSize: 14,
                      }}
                    />
                  </span>
                ),
                children: charts["mutation"](),
              },
            ]}
          />
        </DashboardItem>
      </Quadrant>
      <Quadrant className="quadrant">
        <DashboardItem>
          <Tabs
            defaultActiveKey="1"
            items={[
              {
                key: 1,
                label: (
                  <span>
                    Adverse events{" "}
                    <ZoomInOutlined
                      onClick={() => {
                        setModalOpen("adverseEvents");
                      }}
                      style={{
                        color: "#0e3651",
                        marginLeft: 10,
                        fontSize: 14,
                      }}
                    />
                  </span>
                ),
                children: charts["adverseEvents"](),
              },
            ]}
          />
        </DashboardItem>
        {/* <DashboardItem style={{ width: 1080 }}>
        </DashboardItem>

        <DashboardItem>
          <Histogram />
        </DashboardItem>
        <DashboardItem
          style={{
            width: 550,
            height: 550,
            float: "right",
            padding: 10,
          }}
        >
          <iframe src="/Chart/pieChart.html"></iframe>
        </DashboardItem> */}
      </Quadrant>
      <Modal
        style={{ background: "#fff", padding: 30 }}
        open={modalOpen}
        onCancel={closeModal}
        onOk={closeModal}
        centered={true}
        width={window.innerWidth * 0.7}
        heigth={800}
        footer={null}
      >
        {modalOpen && charts[modalOpen](1.3)}
      </Modal>
    </Wrapper>
  );
};

export default Dashboard;

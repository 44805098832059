import { Header, LoginForm, InputGroup, SubmitButton } from "./style";

import { loginAction, resetPassword } from "../../helpers/api";
import { useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Button, Alert, Form } from "antd";

const ResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [msg, setMsg] = useState({ msg: "", type: "" });
  const [loading, setLoading] = useState(false);

  const searchParams = window.location.search;

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e?.stopPropagation();
    e?.preventDefault();
    setLoading(true);
    resetPassword(searchParams, password, passwordConfirm)
      .then((res) => {
        setMsg({
          msg: (
            <div>
              You’ve successfully changed your password.{" "}
              <Link to="/">Click here</Link> to sign in
            </div>
          ),
          type: "success",
        });
        setLoading(false);
        setTimeout(() => {
          navigate("/");
        }, 1000);
      })
      .catch((e) => {
        setLoading(false);
        setMsg({ msg: "oops! something went wrong", type: "error" });
      });
  };

  return (
    <>
      <LoginForm style={{ marginTop: props.popup ? 0 : undefined }}>
        <Form onFinish={handleSubmit}>
          {msg.msg ? (
            <Alert showIcon message={msg.msg} type={msg.type} />
          ) : null}

          <InputGroup>
            <label>New Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>
          <InputGroup>
            <label>Confirm Password</label>
            <input
              type="password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
            />
          </InputGroup>

          <SubmitButton>
            <Button htmlType="submit" loading={loading} onClick={handleSubmit}>
              Reset
            </Button>
          </SubmitButton>
        </Form>
      </LoginForm>
    </>
  );
};

export default ResetPassword;

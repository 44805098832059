import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { AppWrapper } from "../../components/shared/Elements";
import { getUserData } from "../../helpers/api";
import { MenuItem } from "../Menu/style";
import PasswordForm from "./PasswordForm";
import UserForm from "./UserForm";
import ProjectsForm from "./ProjectsForm";

const Profile = (props) => {
  const [user, setUser] = useState("");
  const [profileModalVisible, setProfileModalVisible] = useState(false);
  const [passwordModalVisible, setPasswordModalVisible] = useState(false);
  const [projectsModalVisible, setProjectsModalVisible] = useState(false);
  const [userData, setUserData] = useState("");

  useEffect(() => {
    const email = localStorage.getItem("email");
    getUserData(email).then(({ data }) => {
      setUserData(data);
    });

    setUser(email);
  }, [user]);

  const toggleProfileModal = () => {
    setProfileModalVisible(!profileModalVisible);
  };

  const togglePasswordModal = () => {
    setPasswordModalVisible(!passwordModalVisible);
  };

  const toggleProjectsModal = () => {
    setProjectsModalVisible(!projectsModalVisible);
  };

  return (
    <AppWrapper style={{ flexDirection: "column" }}>
      <div>
        <span>Welcome </span>
        <b>{userData.display_name}</b>
      </div>
      <br />
      <div>
        <MenuItem onClick={toggleProfileModal}>Edit profile</MenuItem>
        <MenuItem onClick={togglePasswordModal}>Change password</MenuItem>
        <MenuItem onClick={toggleProjectsModal}>Projects</MenuItem>
      </div>

      <Modal
        visible={profileModalVisible}
        closable={true}
        onCancel={() => setProfileModalVisible(false)}
        style={{ background: "transparent" }}
        footer={false}
      >
        <UserForm {...userData} />
      </Modal>

      <Modal
        visible={passwordModalVisible}
        closable={true}
        onCancel={() => setPasswordModalVisible(false)}
        style={{ background: "transparent" }}
        footer={false}
      >
        <PasswordForm {...userData} />
      </Modal>

      <Modal
        visible={projectsModalVisible}
        closable={true}
        onCancel={() => setProjectsModalVisible(false)}
        style={{ background: "transparent" }}
        footer={false}
      >
        <ProjectsForm
          {...userData}
          projects={props.projects}
          getProjects={props.getProjects}
        />
      </Modal>
    </AppWrapper>
  );
};

export default Profile;

import { useState } from "react";

import { Form, Input, Select, Switch, Alert, Button } from "antd";
import { LoginForm, InputGroup } from "../SignUp/style";

import { signupAction, sendVerificationEmail } from "../../helpers/api";
import { SubmitButton } from "../LoginPage/style";
import { OmitProps } from "antd/lib/transfer/ListBody";

const { Option } = Select;

let timer = null;

const PasswordForm = (props) => {
  const [msg, setMsg] = useState({ msg: "", type: "" });
  const [loading, setLoading] = useState(false);

  const updateMessage = (msg) => {
    clearTimeout(timer);
    setMsg(msg);
    timer = setTimeout(() => {
      setMsg({ msg: "", type: "" });
    }, 6000);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    updateMessage({ msg: "", type: "" });

    signupAction(values)
      .then((res) => {
        sendVerificationEmail();
        updateMessage({ msg: "Please verify your email!", type: "success" });
        setLoading(false);
      })
      .catch((e) => {
        const detail = e?.response?.data?.detail;
        const msg = Array.isArray(detail) ? detail?.[0]?.msg : detail;
        updateMessage({ msg, type: "error" });
        setLoading(false);
      });
  };

  return (
    <>
      <LoginForm style={{ marginTop: 50, width: 500, height: 300 }}>
        {msg.msg ? <Alert showIcon message={msg.msg} type={msg.type} /> : null}
        <Form onFinish={handleSubmit}>
          <InputGroup>
            <Form.Item
              name="password"
              label="New Password"
              rules={[{ required: true }]}
            >
              <Input type="password" />
            </Form.Item>
          </InputGroup>
          <InputGroup>
            <Form.Item
              name="confirm_password"
              label="Confirm Password"
              rules={[{ required: true }]}
            >
              <Input type="password" />
            </Form.Item>
          </InputGroup>
          {/* 
          <InputGroup>
            <Form.Item
              name="password"
              label="Confirm Password"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </InputGroup> */}

          <SubmitButton>
            <Button htmlType="submit" loading={loading}>
              Change password
            </Button>
          </SubmitButton>
        </Form>
      </LoginForm>
    </>
  );
};

export default PasswordForm;

// Libraries
import { useEffect, useState } from "react";

// Ui elements
import { Table } from "antd";
import { AppWrapper } from "../../components/shared/Elements";

// api
import { purchaseHistory } from "../../helpers/api";

// helpers
import { purchaseHistoryCols } from "../../helpers/tableColumns";

const PurchaseHistory = () => {
  // state
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    purchaseHistory().then(({ data }) => {
      const formatted = data.map((obj) => obj.case_reports);
      setData(formatted[0]);
      setLoading(false);
    });
  }, []);

  return (
    <AppWrapper >
      <Table
        columns={purchaseHistoryCols}
        dataSource={data}
        loading={loading}
      />
    </AppWrapper>
  );
};

export default PurchaseHistory;

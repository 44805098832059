// Styles
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Auths = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = window.location.search;
    const x = new URLSearchParams(searchParams);
    const { mode } = Object.fromEntries(x);

    if (mode === "resetPassword") {
      navigate("/reset_password/" + searchParams);
    } else if (mode === "verifyEmail") {
      navigate("/verify_email/" + searchParams);
    }
  }, []);

  return <div></div>;
};

export default Auths;

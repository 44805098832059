import { Alert, Spin } from "antd";
import { useState } from "react";
import { sendVerificationEmail } from "../../helpers/api";
import styled from "styled-components";
import { Link } from "react-router-dom";

const MsgWrapper = styled.div`
  padding: 40px;
  background: #fff;
`;

const VerifyEmailPopup = () => {
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const resendEmail = () => {
    setLoading(true);

    sendVerificationEmail().then((res) => {
      setEmailSent(true);
      setLoading(false);
    });
  };

  return (
    <MsgWrapper>
      <Spin
        spinning={loading}
        style={{
          position: "absolute",
          top: 40,
          right: 40,
        }}
      />
      {emailSent ? (
        <>
          <Alert type="success" message="Email sent" showIcon={true} />
          <Link
            to="/"
            style={{
              display: "block",
              marginTop: 30,
              textAlign: "center",
            }}
          >
            Click here to login
          </Link>
        </>
      ) : (
        <p>
          We've sent you a magic link to confirm your account. Please click on
          the link to access OpenCase.AI's full set of features. Click{" "}
          <span
            style={{
              color: "blue",
              cursor: "pointer",
            }}
            onClick={resendEmail}
          >
            HERE
          </span>{" "}
          to receive a new link
        </p>
      )}
    </MsgWrapper>
  );
};

export default VerifyEmailPopup;

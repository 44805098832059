import { useState, useRef } from "react";

import {
  Form,
  Input,
  Select,
  Switch,
  Alert,
  Button,
  Modal,
  Popover,
} from "antd";
import { Header, LoginForm, InputGroup } from "./style";

import { signupAction } from "../../helpers/api";
import { SubmitButton } from "../LoginPage/style";
import { Link, useNavigate } from "react-router-dom";
import { CheckCircleTwoTone, CloseCircleTwoTone } from "@ant-design/icons";
import logo from "../../assets/images/OpenCase transparent.png";

const SignUp = () => {
  const [msg, setMsg] = useState({ msg: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [popupVisible, setPopupVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordRules, setPasswordRules] = useState({});
  const [hasErrors, setHasErrors] = useState(false);
  const [validPhone, setValidPhone] = useState(true);

  const passwordElement = useRef(null);
  const navigate = useNavigate();

  const updateMessage = (msg) => {
    setMsg(msg);
  };

  const onClose = () => {
    setPopupVisible(false);
    navigate("/");
  };

  const handleSubmit = (values) => {
    if (hasErrors) {
      passwordElement.current.focus();
      return;
    }
    setLoading(true);
    updateMessage({ msg: "", type: "" });
    for (let key in values) {
      if (!values[key]) values[key] = null;
    }

    signupAction(values)
      .then((res) => {
        updateMessage({ msg: "Please verify your email!", type: "success" });
        setLoading(false);
        setPopupVisible(true);
        setEmail(values.email);
      })
      .catch((e) => {
        const detail = e?.response?.data?.detail;
        const msg = Array.isArray(detail) ? detail?.[0]?.msg : detail;
        updateMessage({ msg, type: "error" });
        setLoading(false);
      });
  };

  const validatePassword = (value) => {
    const errors = {
      minLength: true,
      upperCase: false,
      lowerCase: false,
      numerical: false,
      specialChar: false,
    };

    if (value?.length < 8) errors.minLength = false;
    if (/(?=.*?[A-Z])/.test(value)) errors.upperCase = true;
    if (/(?=.*?[a-z])/.test(value)) errors.lowerCase = true;
    if (/(?=.*?[0-9])/.test(value)) errors.numerical = true;
    if (/(?=.*?[#?!@$%^&*-])/.test(value)) errors.specialChar = true;

    const hasErrors = Object.values(errors).filter((e) => e === false);
    setHasErrors(hasErrors.length > 0);

    setPasswordRules(errors);
  };

  const validatePhone = (phone) => {
    const regex = !phone || /(1-)?[0-9]{3}-[0-9]{3}-[0-9]{4}$/.test(phone);
    setValidPhone(regex);
  };

  const PhoneValidationMessage = () => {
    return <div>Enter a valid phone number (1-123-456-7890)</div>;
  };

  const content = () => {
    return (
      <div>
        <div>
          {passwordRules.minLength ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff0303" />
          )}{" "}
          8 Characters minimum
        </div>
        <div>
          {passwordRules.lowerCase ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff0303" />
          )}{" "}
          1 lower case
        </div>
        <div>
          {passwordRules.upperCase ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff0303" />
          )}{" "}
          1 upper case
        </div>
        <div>
          {passwordRules.specialChar ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff0303" />
          )}{" "}
          1 special character ( ! @ # $ % & )
        </div>
        <div>
          {passwordRules.numerical ? (
            <CheckCircleTwoTone twoToneColor="#52c41a" />
          ) : (
            <CloseCircleTwoTone twoToneColor="#ff0303" />
          )}{" "}
          1 numerical
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        visible={popupVisible}
        title="Confirm your email"
        onCancel={onClose}
        onOk={onClose}
        style={{
          background: "#fff",
        }}
        okText="Close"
      >
        <div>
          We emailed a confirmation link to {email}. Click the link to log in
          and gain access to OpenCase. <br />
          Haven’t received it yet? Make sure to check your spam folder.
        </div>
      </Modal>
      <LoginForm style={{ marginTop: 50 }}>
        {msg.msg ? <Alert showIcon message={msg.msg} type={msg.type} /> : null}
        <div className="loginContainer">
          <div className="banner">
            <img src={logo} alt="logo" />
            <Header>
              Access thousands of curated electronic oncology case reports.
              Simple searches and intuitive visualizations will have you
              downloading research-grade real world data in no time.
            </Header>
          </div>

          <Form onFinish={handleSubmit} className="loginForm">
            <InputGroup>
              <Form.Item
                name="email"
                rules={[{ required: true }]}
                shouldUpdate
                label="Email"
              >
                <Input />
              </Form.Item>
            </InputGroup>

            <InputGroup>
              <Popover
                defaultVisible={false}
                content={content}
                title="Password rules"
                placement="right"
                trigger="focus"
              >
                <Form.Item
                  name="password"
                  rules={[{ required: true }]}
                  label="Password"
                >
                  <Input
                    type="password"
                    onChange={(e) => validatePassword(e.target.value)}
                    ref={passwordElement}
                  />
                </Form.Item>
              </Popover>
            </InputGroup>

            <InputGroup>
              <Form.Item
                name="confirm_password"
                rules={[{ required: true }]}
                label="Confirm Password"
              >
                <Input type="password" />
              </Form.Item>
            </InputGroup>

            <InputGroup>
              <Popover
                content={PhoneValidationMessage}
                placement="right"
                open={!validPhone}
              >
                <Form.Item
                  name="phone_number"
                  rules={[{ required: false }]}
                  label="Phone"
                >
                  <Input
                    type="tel"
                    onBlur={(e) => validatePhone(e.target.value)}
                  />
                </Form.Item>
              </Popover>
            </InputGroup>

            <InputGroup>
              <Form.Item
                name="first_name"
                rules={[{ required: true }]}
                label="First name"
              >
                <Input />
              </Form.Item>
            </InputGroup>

            <InputGroup>
              <Form.Item
                name="last_name"
                rules={[{ required: true }]}
                label="Last name"
              >
                <Input />
              </Form.Item>
            </InputGroup>

            <InputGroup>
              <Form.Item
                name="name"
                rules={[{ required: true }]}
                label="Organization"
              >
                <Input />
              </Form.Item>
            </InputGroup>

            <InputGroup>
              <Form.Item
                name="address"
                rules={[{ required: false }]}
                label="Address"
              >
                <Input />
              </Form.Item>
            </InputGroup>

            <InputGroup>
              <Form.Item
                name="marketing_opt_in"
                rules={[{ required: false }]}
                label="Marketing opt-in"
                className="formCheckbox"
              >
                <Switch />
              </Form.Item>
            </InputGroup>

            <InputGroup>
              <Form.Item
                name="terms_and_conditions"
                rules={[{ required: true, type: "enum", enum: [true] }]}
                label={"Agree to Terms and Conditions"}
                className="formCheckbox"
                valuePropName="terms_and_conditions"
              >
                <Switch />
              </Form.Item>
            </InputGroup>
            <SubmitButton style={{ bottom: 80 }}>
              <Button htmlType="submit" loading={loading}>
                Sign Up
              </Button>
            </SubmitButton>
            <div
              style={{
                clear: "both",
                textAlign: "center",
                padding: "50px 0 20px 0",
              }}
            >
              Already have an account? <Link to="/">Login here</Link>
            </div>
          </Form>
        </div>
      </LoginForm>
    </>
  );
};

export default SignUp;

import { useEffect, useState } from "react";
import { getPreview } from "../../helpers/api";
import Gantt from "./Gantt";

const PreviewCaseReport = (props) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [characteristics, setCharacteristics] = useState(null);

  useEffect(() => {
    // call the api
    getPreview(props.reportId)
      .then((res) => {
        const x = res.data.patient_journey.map((e) => ({
          ...e,
          start: new Date(e.start),
          end: new Date(e.end),
        }));
        setData(x);
        setCharacteristics(res.data.characteristics);
        setLoading(false);
      })
      .catch((e) => {
        console.log({ e });
        setError(e.response?.data?.detail);
        setLoading(false);
      });
  }, [props.reportId]);

  // return <pre>{JSON.stringify(data, undefined, 2)}</pre>;
  return (
    <Gantt
      error={error}
      data={data}
      characteristics={characteristics}
      loading={loading}
    />
  );
};

export default PreviewCaseReport;

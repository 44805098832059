import styled from "styled-components";

export const Button = styled.button`
  cursor: pointer;
  color: #000;
  padding: 10px 30px;
  box-shadow: none;
  text-align: center;
  font-size: 16px;
`;

export const AppWrapper = styled.div`
  padding: 30px;
  min-height: calc(100vh - 111px);
  display: flex;
  align-items: flex-start;
  */background: #f5f5f5;*/
`;

import styled from "styled-components";

export const Header = styled.h1`
  color: #3a506b;
  text-shadow: 0 3px 10px rgb(255 255 255 / 30%);
  margin: 60px auto;
  padding: 15px;
  text-align: justify;
  font-size: 20px;
`;

export const LoginForm = styled.div`
  width: calc(100vw - 200px);
  max-width: 1000px;
  height: 1400px;
  padding: 20px 30px;
  margin: auto;
  margin-top: 200px;
  background: #efefef;
  border: 1px solid #d9d9d9;
  border-radius: 10px;

  left: 100px;

  .loginContainer {
    height: calc(100vh - 550px);
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    align-content: center;
    flex-wrap: nowrap;
    flex-direction: row-reverse;
  }

  .loginForm {
    padding: 35px 50px;
    border-radius: 5px;
    background: #3a506b;
    color: #fff;

    input {
      padding: 12px;
      width: 350px;
    }

    label {
      color: #fff;
    }

    .ant-row {
      flex-direction: column;
    }

    > div {
      padding: 7px 20px;
    }

    .formCheckbox {
      .ant-row {
        flex-direction: row;
      }
      
      .ant-form-item-label {
        flex-grow: 1;
      }

      label {
        width: 300px;
      }
    }
  }

  .banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    margin-top: 50px;
  }
`;

export const InputGroup = styled.div`
  padding: 15px 20px;
  // margin-bottom: 10px;

  label,
  .ant-form-horizontal .ant-form-item-label,
  .ant-form-item-label {
    font-size: 16px;
    width: 150px;
    display: inline-block;
    text-align: left;
  }

  input {
    width: 270px;
    border: none;
    padding: 5px;
    border-radius: 3px;
  }

  .ant-form-item {
    margin-bottom: 0;
  }
`;

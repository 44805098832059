import { useEffect, useState } from "react";

import {
  Form,
  Input,
  Select,
  Switch,
  Alert,
  Button,
  Tag,
  Popconfirm,
} from "antd";
import { LoginForm, InputGroup } from "../SignUp/style";

import {
  signupAction,
  sendVerificationEmail,
  addProject,
} from "../../helpers/api";
import { SubmitButton } from "../LoginPage/style";
import { OmitProps } from "antd/lib/transfer/ListBody";

const { Option } = Select;

let timer = null;

const ProjectsForm = (props) => {
  const [msg, setMsg] = useState({ msg: "", type: "" });
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);

  const updateMessage = (msg) => {
    clearTimeout(timer);
    setMsg(msg);
    timer = setTimeout(() => {
      setMsg({ msg: "", type: "" });
    }, 6000);
  };

  const handleSubmit = (values) => {
    setLoading(true);
    updateMessage({ msg: "", type: "" });
    const { identifier, description } = values;

    addProject(identifier, description)
      .then((res) => {
        updateMessage({ msg: "Project added", type: "success" });
        props.getProjects().then((res) => {
          setLoading(false);
        });
      })
      .catch((e) => {
        const detail = e?.response?.data?.detail;
        const msg = Array.isArray(detail) ? detail?.[0]?.msg : detail;
        updateMessage({ msg, type: "error" });
        setLoading(false);
      });
  };

  useEffect(() => {
    // props.getProjects()
  }, []);

  const deleteProject = (id) => {};

  return (
    <>
      <LoginForm style={{ marginTop: 50, width: 500, height: 500 }}>
        {msg.msg ? <Alert showIcon message={msg.msg} type={msg.type} /> : null}
        <h2>Current projects :</h2>
        {props.projects.map((e) => (
          <Popconfirm>
            <Tag
              style={{ padding: "5px 10px", margin: "5px" }}
              onClose={() => deleteProject(e.id)}
            >
              {e.description}
            </Tag>
          </Popconfirm>
        ))}
        <br />
        <br />
        <hr />
        <h2>Add a new Project :</h2>
        <Form onFinish={handleSubmit}>
          <InputGroup>
            <Form.Item
              name="identifier"
              label="Identifier"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </InputGroup>

          <InputGroup>
            <Form.Item
              name="description"
              label="Description"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </InputGroup>
         
          <SubmitButton>
            <Button htmlType="submit" loading={loading}>
              Add project
            </Button>
          </SubmitButton>
        </Form>
      </LoginForm>
    </>
  );
};

export default ProjectsForm;

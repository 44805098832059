import styled from "styled-components";

export const SearchForm = styled.div`
  width: 45%;
  height: 660px;
  display: flex;
  align-items: flex-start;
  float: left;
  background: #3a506b;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px;
  border-radius: 5px;

  button {
    text-align: left;
    height: 40px;
  }

  .ant-form {
    width: 100%;
  }

  .ant-select-show-search.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    padding: 5px;
  }

  .ant-form-item {
    margin-bottom: 15px;
  }

  .ant-input-affix-wrapper,
  .ant-btn,
  .ant-select-auto-complete {
    margin: 10px 0;
  }

  .ant-select {
    min-width: 100%;
  }

  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px;
    padding: 4px 11px;
  }

  h2 {
    text-align: center;
    font-size: 25px;
    color: #fff;
    margin-top: -10px;
  }
`;

export const SearchCount = styled.div`
  float: left;
  margin-left: 20px;
  font-size: 20px;
  width: 50%;
  text-align: center;
  height: calc(100vh - 220px);
  display: flex;
  align-items: center;
  margin-top: -55px;
  justify-content: center;
  color: #fff;
  flex-direction: column;
  flex-gap: 100px;
  b {
    margin: 0 5px;
  }
`;

// Libraries
import { useEffect, useState } from "react";

// Ui elements
import { Alert, Button, Modal, notification, Spin, Table, Tag } from "antd";
import { AppWrapper } from "../../components/shared/Elements";

// api
import {
  getCartContent,
  getProject,
  purchaseAction,
  removeFromCartRequest,
} from "../../helpers/api";

// helpers
import {
  cartContentCols,
  newColumns,
  cartColumns,
} from "../../helpers/tableColumns";
import { SearchResultWrapper } from "../SearchResult/style";
import PreviewCaseReport from "../SearchResult/PreviewCaseReport";

const Cart = ({ selectedProject }) => {
  // state
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [purchasing, setPurchasing] = useState(false);
  const [cartStatus, setCartStatus] = useState("");
  const [projectName, setProjectName] = useState("");
  const [modalVisible, setModalVisible] = useState(false);
  const [purchaseModalVisible, setPurchaseModalVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [modalId, setModalId] = useState(null);

  useEffect(() => {
    setLoading(true);
    const projectId = localStorage.getItem("selectedProject");
    getCartContent(projectId)
      .then((res) => {
        setData(res.data.case_reports);
        setCartStatus(res.cartStatus);
        setLoading(false);
      })
      .catch((e) => {
        setData([]);
        setCartStatus("Empty");
        setLoading(false);
      });

    getProject(projectId).then((res) => {
      setProjectName(res?.data?.description);
    });
  }, [selectedProject]);

  const onClose = () => {
    setModalVisible(false);
  };

  const purchaseModalClose = () => {
    setPurchaseModalVisible(false);
  };

  const purchase = () => {
    const projectId = localStorage.getItem("selectedProject");
    setPurchasing(true);
    purchaseAction(projectId)
      .then((res) => {
        setPurchasing(false);
        setMessage(
          "Thank you for your interest in OpenCase.AI data  We will contact you shortly regarding your order."
        );
        setPurchaseModalVisible(true);
      })
      .catch((e) => {
        setPurchasing(false);
        setMessage("oops!, something went wrong");
        setPurchaseModalVisible(true);
      });
  };

  const showPreview = (id) => {
    setModalId(id);
    setModalVisible(true);
  };

  const removeFromCart = (id) => {
    removeFromCartRequest(id)
      .then((res) => {
        notification.open({
          message: "Item removed from cart.",
          duration: 5,
          type: "success",
        });
      })
      .catch((e) => {
        notification.open({
          message: "Oops, something went wrong.",
          duration: 5,
          type: "error",
        });
      });
  };

  return (
    <AppWrapper style={{ flexDirection: "column" }}>
      <div style={{ width: "100%", paddingBottom: 20 }}>
        <h3 style={{ display: "inline-block" }}>
          {cartStatus} Cart for project : {projectName}
          <Spin style={{ marginLeft: 10 }} spinning={loading} />
        </h3>
        <Button
          loading={purchasing}
          type="primary"
          style={{ float: "right" }}
          onClick={purchase}
        >
          Purchase
        </Button>
      </div>
      <SearchResultWrapper
        id="searchResults"
        style={{ flexDirection: "column" }}
      >
        {/* {error ? <Alert type="error" message={error} closable /> : null} */}
        <Table
          style={{
            height: "calc(100vh - 160px)",
          }}
          loading={loading}
          // columns={columns(selectedKeys, showPreview, data[0])}
          columns={cartColumns(showPreview, removeFromCart)}
          dataSource={data}
          pagination={{
            pageSize: 13,
          }}
          onRow={(record, ndx) => ({
            onClick: () => {
              // selectRow({ id: ndx });
              setModalId(record.id);
            },
          })}
          rowClassName="resultRow"
          showHeader={false}
          rowKey={(e) => e.id}
        />
        <Modal
          visible={modalVisible}
          closable={true}
          onCancel={() => onClose()}
          style={{ background: "#fff", marginTop: -50 }}
          footer={false}
          width={1400}
        >
          <PreviewCaseReport reportId={modalId} />
        </Modal>
      </SearchResultWrapper>

      <Modal
        style={{ background: "#fff" }}
        visible={purchaseModalVisible}
        onCancel={purchaseModalClose}
        onOk={purchaseModalClose}
        title={"Purchase"}
      >
        <div>{message}</div>
      </Modal>
    </AppWrapper>
  );
};

export default Cart;

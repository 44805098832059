import styled from "styled-components";

const Wrapper = styled.div`
  background: #3a506b;
  width: 270px;
  float: left;
  padding: 20px;
  height: calc(100vh - 100px);
  margin-top: -1px;
  border-right: 1px solid #2e3a48;

  a {
    display: block;
    color: #000;
    margin: 20px 0;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    
  }
`;

export default Wrapper;

// old search form
// Libraries
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Styles
import { SearchForm, SearchCount } from "./style";

// api
import { getDropdownValues, autocomplete } from "../../helpers/autocompleteApi";
import { getSearchCount } from "../../helpers/api";

// ui elements
import { Select, Form, Button, Popover, Spin, Alert } from "antd";
import { AppWrapper } from "../../components/shared/Elements";
import { InfoCircleOutlined, CloseCircleTwoTone } from "@ant-design/icons";

const { Option } = Select;

const initialState = {
  adverse_events: "",
  main_cancer: "Lung",
  diagnosis: "",
  treatments: "",
  biomarkers: "",
  outcome: "",
  mutations: "",
};

const Search = () => {
  const navigate = useNavigate();
  // state
  const [dropdownValues, setDropdownValues] = useState({});
  const [formData, setFormData] = useState(initialState);
  const [options, setOptions] = useState({});
  const [count, setCount] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lengthError, setLengthError] = useState("");
  const [form] = Form.useForm();

  useEffect(() => {
    getDropdownValues().then(({ data }) => {
      setDropdownValues(data);
    });

    updateForm("main_cancer", "Lung");
  }, []);

  const updateForm = (field, value, reset = false) => {
    const data = reset ? initialState : JSON.parse(JSON.stringify(formData));
    data[field] = value === [] ? undefined : value;

    setFormData(data);

    // update search count
    setLoading(true);
    getSearchCount(data).then(({ data }) => {
      setCount(+data || 0);
      setLoading(false);
    });
  };

  const handleSubmit = (values) => {
    navigate("/searchResult", { state: { formData: values } });
  };

  const clearForm = () => {
    form.resetFields();
    updateForm("main_cancer", "Lung", true);
  };

  const handleSearch = (field, value) => {
    // query the backend to get the list of options
    autocomplete(field, value, formData.main_cancer).then(({ data }) => {
      const formatted = data.map((value) => ({ value }));
      setOptions({ ...options, [field]: formatted });
    });
  };

  const showLengthError = (section) => {
    setLengthError(section);
    setTimeout(() => {
      setLengthError("");
    }, 3000);
  };

  return (
    <AppWrapper style={{ alignItems: "center" }}>
      <SearchForm>
        <h2>
          Database search{" "}
          <Popover
            content={
              <div>
                <p>
                  Maximum 5 items per search field. <br /> Search results show
                  number of case reports where minimum one searched item is
                  present per search field.
                </p>
              </div>
            }
            title={null}
          >
            <InfoCircleOutlined
              style={{
                fontSize: 18,
                marginLeft: 5,
                cursor: "pointer",
              }}
            />
          </Popover>
        </h2>
        <Form
          onValuesChange={(x) => {
            const [key, value] = Object.entries(x)[0];
            updateForm(key, value);
          }}
          onFinish={handleSubmit}
          form={form}
        >
          <Form.Item name="main_cancer">
            <Select
              // value={formData.main_cancer}
              // onChange={(value) => {
              //   updateForm("main_cancer", value);
              // }}
              placeholder="Primary Cancer"
              defaultValue="Lung"
            >
              {dropdownValues.main_cancer?.map((value) => (
                <Option value={value}>{value}</Option>
              ))}
            </Select>
          </Form.Item>
          <Popover
            content={
              <span>
                <CloseCircleTwoTone
                  twoToneColor="#ff0303"
                  style={{ marginRight: 10 }}
                />
                Maximum 5 items per search field.
              </span>
            }
            title={null}
            open={lengthError === "diagnosis"}
            trigger="null"
            placement="right"
            color="#fff2f0"
          >
            <Form.Item
              name="diagnosis"
              getValueFromEvent={(value) => {
                if (value?.length >= 6) {
                  showLengthError("diagnosis");
                }
                return value.slice(0, 5);
              }}
            >
              <Select
                mode="tags"
                notFoundContent={null}
                defaultActiveFirstOption={false}
                onSearch={(value) => handleSearch("diagnosis", value)}
                placeholder="Diagnosis"
                disabled={formData.main_cancer ? false : true}
                title={formData.main_cancer ? "" : "Please select main cancer"}
                showArrow={false}
                showSearch
              >
                {options.diagnosis?.map(({ value }, ndx) => (
                  <Option value={value} key={`${value}-${ndx}`}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Popover>

          <Form.Item name="metastastic">
            <Select
              value={formData.metastastic}
              placeholder="Metastastic"
              disabled={formData.main_cancer ? false : true}
              title={formData.main_cancer ? "" : "Please select main cancer"}
            >
              {dropdownValues.metastastic?.map((value) => (
                <Option value={value}>{value}</Option>
              ))}
            </Select>
          </Form.Item>

          <Popover
            content={
              <span>
                <CloseCircleTwoTone
                  twoToneColor="#ff0303"
                  style={{ marginRight: 10 }}
                />
                Maximum 5 items per search field.
              </span>
            }
            title={null}
            open={lengthError === "biomarkers"}
            trigger="null"
            placement="right"
            color="#fff2f0"
          >
            <Form.Item
              name="biomarkers"
              getValueFromEvent={(value) => {
                if (value?.length >= 6) {
                  showLengthError("biomarkers");
                }
                return value.slice(0, 5);
              }}
            >
              <Select
                mode="tags"
                notFoundContent={null}
                defaultActiveFirstOption={false}
                onSearch={(value) => handleSearch("biomarkers", value)}
                placeholder="Biomarkers"
                disabled={formData.main_cancer ? false : true}
                title={formData.main_cancer ? "" : "Please select main cancer"}
                showArrow={false}
                showSearch
              >
                {options.biomarkers?.map(({ value }, ndx) => (
                  <Option value={value} key={`${value}-${ndx}`}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Popover>
          <Popover
            content={
              <span>
                <CloseCircleTwoTone
                  twoToneColor="#ff0303"
                  style={{ marginRight: 10 }}
                />
                Maximum 5 items per search field.
              </span>
            }
            title={null}
            open={lengthError === "mutations"}
            trigger="null"
            placement="right"
            color="#fff2f0"
          >
            <Form.Item
              name="mutations"
              getValueFromEvent={(value) => {
                if (value?.length >= 6) {
                  showLengthError("mutations");
                }
                return value.slice(0, 5);
              }}
            >
              <Select
                mode="tags"
                notFoundContent={null}
                defaultActiveFirstOption={false}
                onSearch={(value) => handleSearch("mutations", value)}
                placeholder="Mutations"
                disabled={formData.main_cancer ? false : true}
                title={formData.main_cancer ? "" : "Please select main cancer"}
                showArrow={false}
                showSearch
              >
                {options.mutations?.map(({ value }, ndx) => (
                  <Option value={value} key={`${value}-${ndx}`}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Popover>

          <Popover
            content={
              <span>
                <CloseCircleTwoTone
                  twoToneColor="#ff0303"
                  style={{ marginRight: 10 }}
                />
                Maximum 5 items per search field.
              </span>
            }
            title={null}
            open={lengthError === "treatments"}
            trigger="null"
            placement="right"
            color="#fff2f0"
          >
            <Form.Item
              name="treatments"
              getValueFromEvent={(value) => {
                if (value?.length >= 6) {
                  showLengthError("treatments");
                }
                return value.slice(0, 5);
              }}
            >
              <Select
                mode="tags"
                notFoundContent={null}
                defaultActiveFirstOption={false}
                onSearch={(value) => handleSearch("treatments", value)}
                placeholder="Treatments"
                disabled={formData.main_cancer ? false : true}
                title={formData.main_cancer ? "" : "Please select main cancer"}
                showArrow={false}
                showSearch
              >
                {options.treatments?.map(({ value }, ndx) => (
                  <Option value={value} key={`${value}-${ndx}`}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Popover>
          <Popover
            content={
              <span>
                <CloseCircleTwoTone
                  twoToneColor="#ff0303"
                  style={{ marginRight: 10 }}
                />
                Maximum 5 items per search field.
              </span>
            }
            title={null}
            open={lengthError === "adverse_events"}
            trigger="null"
            placement="right"
            color="#fff2f0"
          >
            <Form.Item
              name="adverse_events"
              getValueFromEvent={(value) => {
                if (value?.length >= 6) {
                  showLengthError("adverse_events");
                }
                return value.slice(0, 5);
              }}
            >
              <Select
                mode="tags"
                notFoundContent={null}
                defaultActiveFirstOption={false}
                onSearch={(value) => handleSearch("adverse_events", value)}
                placeholder="Adverse events"
                disabled={formData.main_cancer ? false : true}
                title={formData.main_cancer ? "" : "Please select main cancer"}
                showArrow={false}
                showSearch
              >
                {options.adverse_events?.map(({ value }, ndx) => (
                  <Option value={value} key={`${value}-${ndx}`}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Popover>
          <Popover
            content={
              <span>
                <CloseCircleTwoTone
                  twoToneColor="#ff0303"
                  style={{ marginRight: 10 }}
                />
                Maximum 5 items per search field.
              </span>
            }
            title={null}
            open={lengthError === "outcomes"}
            trigger="null"
            placement="right"
            color="#fff2f0"
          >
            <Form.Item
              name="outcomes"
              getValueFromEvent={(value) => {
                if (value?.length >= 6) {
                  showLengthError("outcomes");
                }
                return value.slice(0, 5);
              }}
            >
              <Select
                mode="tags"
                notFoundContent={null}
                defaultActiveFirstOption={false}
                onSearch={(value) => handleSearch("outcomes", value)}
                placeholder="Outcomes"
                disabled={formData.main_cancer ? false : true}
                title={formData.main_cancer ? "" : "Please select main cancer"}
                showArrow={false}
                showSearch
              >
                {options.outcomes?.map(({ value }, ndx) => (
                  <Option value={value} key={`${value}-${ndx}`}>
                    {value}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Popover>
          <Button
            type="primary"
            // disabled={count >= 900}
            title={formData.main_cancer ? "" : "Please select main cancer"}
            htmlType="submit"
            style={{ textAlign: "center", width: "65%" }}
          >
            Search
          </Button>

          <Button
            title={"Clear form entries"}
            style={{
              textAlign: "center",
              width: "30%",
              float: "right",
              color: "#fff",
              background: "#5abdbb",
            }}
            onClick={clearForm}
          >
            Clear form
          </Button>
        </Form>
        <div
          style={{
            // background: "#fff",
            color: "#fff",
            padding: 10,
            borderRadius: 3,
            marginTop: 13,
            fontSize: 18,
          }}
        >
          Did not find what you’re looking for?{" "}
          <a href="mailto:info@biospark.ai ">Contact us</a> for advanced search
          alternatives.
        </div>
      </SearchForm>
      {count !== null ? (
        <SearchCount>
          {loading && <Spin size="large" />}
          {/* {loading ? (
            <Spin size="large" />
          ) : (
            count >= 900 && (
              <Alert
                type="error"
                message="Please further refine your search.  For search results beyond 900, please contact us for advanced support."
              />
            )
          )} */}

          <div>
            Found <b> {count} </b> case report correponding to the search
            criteria
          </div>
        </SearchCount>
      ) : null}
    </AppWrapper>
  );
};

export default Search;

const romanNumbers = {
  I: 1,
  II: 2,
  III: 3,
  IV: 4,
  V: 5,
  VI: 6,
  VII: 7,
  VIII: 8,
  IX: 9,
  X: 10,
  XI: 11,
  XII: 12,
  XIII: 13,
  XIV: 14,
  XV: 15,
  XVI: 16,
  XVII: 17,
  XVIII: 18,
  XIX: 19,
  XX: 20,
  XXI: 21,
  XXII: 22,
  XXIII: 23,
  XXIV: 24,
  XXV: 25,
  XXVI: 26,
  XXVII: 27,
  XXVIII: 28,
  XXIX: 29,
  XXX: 30,
  XXXI: 31,
  XXXII: 32,
  XXXIII: 33,
  XXXIV: 34,
  XXXV: 35,
  XXXVI: 36,
  XXXVII: 37,
  XXXVIII: 38,
  XXXIX: 39,
  XL: 40,
  XLI: 41,
  XLII: 42,
  XLIII: 43,
  XLIV: 44,
  XLV: 45,
  XLVI: 46,
  XLVII: 47,
  XLVIII: 48,
  XLIX: 49,
  L: 50,
  LI: 51,
  LII: 52,
  LIII: 53,
  LIV: 54,
  LV: 55,
  LVI: 56,
  LVII: 57,
  LVIII: 58,
  LIX: 59,
  LX: 60,
  LXI: 61,
  LXII: 62,
  LXIII: 63,
  LXIV: 64,
  LXV: 65,
  LXVI: 66,
  LXVII: 67,
  LXVIII: 68,
  LXIX: 69,
  LXX: 70,
  LXXI: 71,
  LXXII: 72,
  LXXIII: 73,
  LXXIV: 74,
  LXXV: 75,
  LXXVI: 76,
  LXXVII: 77,
  LXXVIII: 78,
  LXXIX: 79,
  LXXX: 80,
  LXXXI: 81,
  LXXXII: 82,
  LXXXIII: 83,
  LXXXIV: 84,
  LXXXV: 85,
  LXXXVI: 86,
  LXXXVII: 87,
  LXXXVIII: 88,
  LXXXIX: 89,
  XC: 90,
  XCI: 91,
  XCII: 92,
  XCIII: 93,
  XCIV: 94,
  XCV: 95,
  XCVI: 96,
  XCVII: 97,
  XCVIII: 98,
  XCIX: 99,
  C: 100,
  CI: 101,
  CII: 102,
  CIII: 103,
  CIV: 104,
  CV: 105,
  CVI: 106,
  CVII: 107,
  CVIII: 108,
  CIX: 109,
  CX: 110,
  CXI: 111,
  CXII: 112,
  CXIII: 113,
  CXIV: 114,
  CXV: 115,
  CXVI: 116,
  CXVII: 117,
  CXVIII: 118,
  CXIX: 119,
  CXX: 120,
  CXXI: 121,
  CXXII: 122,
  CXXIII: 123,
  CXXIV: 124,
  CXXV: 125,
  CXXVI: 126,
  CXXVII: 127,
  CXXVIII: 128,
  CXXIX: 129,
  CXXX: 130,
  CXXXI: 131,
  CXXXII: 132,
  CXXXIII: 133,
  CXXXIV: 134,
  CXXXV: 135,
  CXXXVI: 136,
  CXXXVII: 137,
  CXXXVIII: 138,
  CXXXIX: 139,
  CXL: 140,
  CXLI: 141,
  CXLII: 142,
  CXLIII: 143,
  CXLIV: 144,
  CXLV: 145,
  CXLVI: 146,
  CXLVII: 147,
  CXLVIII: 148,
  CXLIX: 149,
  CL: 150,
};

export default romanNumbers;

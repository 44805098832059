import "antd/dist/antd.css";
import Layout, { AppHolder } from "./containers/Layout";

import Menu from "./containers/Menu";
import LoginPage from "./containers/LoginPage";
import SignUp from "./containers/SignUp";
import Dashboard from "./containers/Dashboard";
import Search from "./containers/Search";
import SearchResult from "./containers/SearchResult";
import PuchaseHistory from "./containers/PuchaseHistory";
import Cart from "./containers/Cart";
import Profile from "./containers/Profile";
import VerifyEmail from "./containers/VerifyEmail";
import Auths from "./containers/Auths";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./helpers/ProtectedRoute";
import { useState } from "react";
import { getProjects } from "./helpers/api";
import ResetPassword from "./containers/ResetPassword";
import SideBar from "./containers/SideBar";
import Subscriptions from "./containers/Subscriptions";

function App() {
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [headerKey, setHeaderKey] = useState(Math.random());

  const updateProjects = () => {
    return getProjects().then((res) => {
      setProjects(res ? [...res.data] : []);
      return res;
    });
  };

  const updateHeader = () => {
    setHeaderKey(Math.random());
  };

  return (
    <BrowserRouter>
      <Layout>
        <Menu
          setSelectedProject={setSelectedProject}
          selectedProject={selectedProject}
          projects={projects}
          updateProjects={updateProjects}
          key={headerKey}
        />
        {/* <SideBar /> */}
        <AppHolder>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/verify_email" element={<VerifyEmail />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/reset_password" element={<ResetPassword />} />
            <Route path="/auths" element={<Auths />} />
            <Route
              path="/dashboard"
              element={<ProtectedRoute component={<Dashboard />} />}
              // element={<ProtectedRoute component={<Dashboard />} />}
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute
                  component={
                    <Profile
                      updateProjects={updateProjects}
                      projects={projects}
                      getProjects={updateProjects}
                    />
                  }
                />
              }
            />
            <Route
              path="/search"
              element={<ProtectedRoute component={<Search />} />}
            />
            <Route
              path="/searchResult"
              element={
                <ProtectedRoute
                  component={<SearchResult updateHeader={updateHeader} />}
                />
              }
            />
            <Route
              path="/purchaseHistory"
              element={<ProtectedRoute component={<PuchaseHistory />} />}
            />
            <Route
              path="/cart"
              element={
                <ProtectedRoute
                  component={<Cart selectedProject={selectedProject} />}
                />
              }
            />
            <Route path="/subscriptions" element={<Subscriptions />} />
            <Route path="/explore/PDL1_NSCLC" element={<Dashboard />} />
          </Routes>
        </AppHolder>
      </Layout>
    </BrowserRouter>
  );
}

export default App;

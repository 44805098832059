import Plot from "react-plotly.js";

const Histogram = ({
  title,
  data = [],
  width,
  mb = 30,
  layoutOptions = {},
  zoom = 1
}) => {
  const height = window.innerHeight * 0.30;
  const plotLayout = {
    width: width * zoom,
    height: height* zoom,
    margin: {
      l: 40,
      r: 0,
      b: mb,
      t: title ? 40 : 0,
    },
    title,
    legend: {
      traceorder: "normal",
      font: {
        family: "sans-serif",
        size: 12,
        color: "#000",
      },
      bgcolor: "#c1d1e5",
      bordercolor: "#FFFFFF",
      borderwidth: 2,
      xanchor: "left",
    },
    hiddenlabels: ["Other"],
    ...layoutOptions,
  };


  return <Plot data={data} layout={plotLayout} />;
};

export default Histogram;

import styled from "styled-components";
import { AppWrapper } from "../../components/shared/Elements";

const DashboardWrapper = styled.div`
  padding: 40px;
  margin-top: 40px;
  min-height: 600px;
`;

export const Title = styled.h1`
  color: #fff;
  margin-top: 100px;
  text-align: center;
  line-height: 50px;
`;

export const DashboardItem = styled.div`
  background: rgba(255, 255, 255, 0.5);
  // background: #fff;
  // width: 850px;
  width: 100%;

  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  float: left;
  padding: 10px;
  padding-bottom: 50px;

  height: 40vh;
  overflow: hidden;
  position: relative;
  
  @media (max-width: 1200px) {
    // height: 500px;
  }

  svg {
    background: transparent !important;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: 480px;
    border: none;
  }

  .infolayer {
    background: red;
  }

  .countryFilter {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 180px;
    z-index: 99;
  }

  .ant-select-selector {
    border-radius: 5px !important;
  }

  .ant-tabs-tab-btn {
    color: #000 !important;
  }

  .ant-tabs-tab {
    font-size: 16px;
  }
`;

export const Quadrant = styled.div`
  width: 45%;
  max-width: 1000px;
`;

export const Wrapper = styled(AppWrapper)`
  padding: 0;
  flex-wrap: wrap;
  align-content: space-around;
  justify-content: space-evenly;
  height: calc(100vh - 100px);
  overflow: auto;

  @media (max-width: 1200px) {
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;

    ${Quadrant} {
      width: 80%;
      margin-top: 50px;
      height: 500px;
    }
  }
`;

export default DashboardWrapper;

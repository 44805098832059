import styled from "styled-components";

const MenuWrapper = styled.div`
  padding: 30px;
  background: #3a506b;
  border-bottom: 1px solid #2e3a48;
  color: #fff;
  height: 100px;

  .logo {
    height: 50px;
  }

  .avatar {
    border-radius: 50%;
    width: 35px;
    float: right;
  }

  .cart {
    width: 35px;
    float: right;
  }
  
  .cartBadge {
    float: right;
    margin-right: 30px;
  }

  .savedSearch {
    float: right;
    width: 40px;
    margin-top: -3px;
    margin-right: 23px;
  }

  .logoWrapper {
    background: #fff;
    display: block;
    float: left;
    margin-right: 50px;
    padding: 10px 20px;
    text-align: center;
    border-radius: 3px;
    margin-top: -18px;
    margin-left: -10px;
  }
`;

export const MenuItem = styled.span`
  color: #000;
  padding: 10px 30px;
  margin-right: 40px;
  font-size: 16px;
  box-shadow: 0 3px 15px 0 rgb(0 0 0 / 10%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
`;

export const HeaderDropDown = styled.div`
  display: inline-block;
  margin-right: 20px;
`;

export default MenuWrapper;

import axios from "axios";
import baseUrl, { dataStorageUrl } from "./baseUrl";

const request = (method, url, data, headers) => {
  const token = localStorage.getItem("token");

  return axios({
    method,
    url,
    data,
    headers: headers || {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  }).catch((e) => {
    const status = e.response?.status;
    if (status === 401) {
      localStorage.setItem("loginPopup", true);
    }
    if (status === 423) {
      localStorage.setItem("VerifyEmailPopup", true);
    }
    throw e;
  });
};

export const loginAction = (username, password) => {
  const bodyFormData = new FormData();

  bodyFormData.append("username", username);
  bodyFormData.append("password", password);

  return request("post", baseUrl + "/login", bodyFormData, {
    "Content-Type": "application/x-www-form-urlencoded",
  }).then((res) => {
    if (res.status === 200) {
      localStorage.setItem("loginPopup", false);
      localStorage.setItem("VerifyEmailPopup", false);
    }
    return res;
  });
};

export const searchResults = (filters, pagination) => {
  for (let i in filters) {
    if (i !== "main_cancer" && i !== "main_treatment")
      filters[i] = filters[i] ? filters[i] : undefined;
  }

  delete filters["main_treatment"];

  return request("post", baseUrl + "/search/results", filters).then((res) => {
    const queryString = res.data.join("&ids=");

    return request(
      "get",
      dataStorageUrl +
        "/casereports/tableview" +
        "?ids=" +
        queryString +
        "&" +
        pagination,
      {
        "Content-Type": "application/json",
      }
    );
  });
};

export const purchaseHistory = () => {
  return request("get", baseUrl + "/purchases/history");
};

export const getSearchCount = (input) => {
  const filters = JSON.parse(JSON.stringify(input));

  for (let i in filters) {
    if (i !== "main_cancer") filters[i] = filters[i] || undefined;
  }

  return request("post", baseUrl + "/search/count", filters).catch((e) => 0);
};

export const signupAction = (data) => {
  const obj = {
    name: data.name,
    identifier: data.name.replace(" ", "-"),
    address: data.address,
    admin: {
      email: data.email,
      password: data.password,
      confirm_password: data.confirm_password,
      first_name: data.first_name,
      last_name: data.last_name,
      phone_number: data.phone_number,
    },
  };

  return request("post", baseUrl + "/organizations", obj, {
    "Content-Type": "application/json",
  });
};

export const sendVerificationEmail = () => {
  const access_token = localStorage.getItem("token");

  return request("post", baseUrl + "/resend_verify_email ", { access_token });
};

export const getUserData = (email) => {
  return request("get", baseUrl + "/users/email/" + email);
};

export const updateUserData = (userId, userData) => {
  return request("put", baseUrl + "/users/" + userId, userData);
};

export const addToShoppingCart = (projectId, case_report_ids) => {
  return request("get", baseUrl + "/carts/" + projectId).then((res) => {
    return request("post", baseUrl + "/carts/" + res.data.id + "/contents", {
      case_report_ids: case_report_ids,
    });
  });
};

export const getCartIds = (projectId) => {
  return request("get", baseUrl + "/carts/" + projectId).then((res) =>
    request("get", baseUrl + "/carts/" + res.data.id + "/contents")
  );
};

export const getCartContent = (projectId) => {
  return getCartIds(projectId).then((res) => {
    const queryString = res.data.case_report_ids.join("&ids=");

    return request(
      "get",
      dataStorageUrl + "/casereports/tableview" + "?ids=" + queryString,
      {
        "Content-Type": "application/json",
      }
    );
  });
};

export const purchaseAction = (projectId) => {
  return request("get", baseUrl + "/carts/" + projectId).then((res) =>
    request("put", baseUrl + "/carts/" + res.data.id, {
      status: "wait_for_payment",
    })
  );
};

export const getProjects = () => {
  return request("get", baseUrl + "/projects");
};

export const addProject = (identifier, description) => {
  return request("post", baseUrl + "/projects/", { identifier, description });
};

export const getProject = (id) => {
  return request("get", baseUrl + "/projects/" + id);
};

export const getUserDetails = (email) => {
  return request("get", baseUrl + "/users/email/" + email);
};

export const sendPasswordEmail = (email) => {
  return request("post", baseUrl + "/send_reset_password_email/", { email });
};

export const getPreview = (reportId) => {
  return request(
    "get",
    `${dataStorageUrl}/casereports/postprocessed/${reportId}/preview?keys=AE&keys=DX`,
    {
      "Content-Type": "application/json",
    }
  );
};

export const removeFromCartRequest = (id) => {
  return request("delete", baseUrl + "/carts/" + id);
};

export const verifyEmail = (queryString) => {
  const x = new URLSearchParams(queryString);
  const params = Object.fromEntries(x);
  delete params.apiKey;

  return request("post", baseUrl + "/verify_email", params, {
    "Content-Type": "application/json",
  });
};

export const resetPassword = (queryString, password, confirm_password) => {
  const x = new URLSearchParams(queryString);
  const { oobCode } = Object.fromEntries(x);

  return request("post", baseUrl + "/reset_password", {
    oobCode,
    password,
    confirm_password,
  });
};

export const sessionCheck = () => {
  return request("get", baseUrl + "/session_check");
};

import styled from "styled-components";

const Layout = styled.div`
  min-height: 100vh;
`;

export const AppHolder = styled.div`
  
`;

export default Layout;

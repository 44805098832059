import { Button, Checkbox, Tooltip } from "antd";
import woman from "../assets/images/woman.png";
import man from "../assets/images/man.png";
import patientHistoryIcon from "../assets/images/patient_history.png";
import { TableCell } from "../containers/SearchResult/style";
import trash from "../assets/images/trash.png";

export const newColumns = (selectedKeys, showPreview) => [
  {
    title: "",
    dataIndex: "",
    render: (x, y, z) => {
      return <Checkbox checked={selectedKeys.includes(z)} />;
    },
  },
  {
    title: "",
    dataIndex: "",
    render: (record) => (
      <img
        src={["male", "man"].includes(record.Sex) ? man : woman}
        width="50"
      />
    ),
  },
  {
    title: "",
    dataIndex: "",
    render: (record) => (
      <TableCell>
        <h3>Characteristics</h3>
        <Tooltip
          trigger="click"
          onClick={(e) => {
            e.stopPropagation();
          }}
          autoAdjustOverflow={true}
          placement="topLeft"
          title={
            <div>
              {record?.Age} {record?.Sex},
              <br />{" "}
              <div style={{ width: "fit-content", maxWidth: 500 }}>
                {record?.Smoking?.split(",").join("\n\r")}
              </div>
            </div>
          }
        >
          <div>
            {record?.Age} {record?.Sex},
            <br /> {record?.Smoking}
          </div>
        </Tooltip>
      </TableCell>
    ),
  },
  {
    title: "",
    dataIndex: "Mutations",
    render: (text) => {
      const genomics = text?.split(",") || [];
      const [g1, g2, g3, ...rest] = genomics;

      return (
        <TableCell>
          <h3>Genomics</h3>
          {rest.length > 0 ? (
            <Tooltip
              trigger="click"
              onClick={(e) => {
                e.stopPropagation();
              }}
              autoAdjustOverflow={true}
              placement="topLeft"
              title={
                <pre style={{ width: "fit-content", maxWidth: 500 }}>
                  {text?.split(",").join("\n\r")}
                </pre>
              }
            >
              <div>
                <div>{g1}</div>
                <div>{g2}</div>
                <div>{g3}</div>
                <h4>{rest.length ? `${rest.length} more...` : null}</h4>
              </div>
            </Tooltip>
          ) : (
            <Tooltip
              trigger="click"
              onClick={(e) => {
                e.stopPropagation();
              }}
              autoAdjustOverflow={true}
              placement="topLeft"
              title={
                <pre style={{ width: "fit-content", maxWidth: 500 }}>
                  {text?.split(",").join("\n\r")}
                </pre>
              }
            >
              <div>{g1}</div>
              <div>{g2}</div>
              <div>{g3}</div>
            </Tooltip>
          )}
        </TableCell>
      );
    },
  },
  {
    title: "",
    dataIndex: "Biomarkers",
    ellipsis: {
      showTitle: false,
    },
    render: (text) => {
      const biomarkers = text?.split(",") || [];
      const [b1, b2, b3, ...rest] = biomarkers;
      return (
        <TableCell>
          <h3>Biomarkers</h3>
          {rest.length > 0 ? (
            <Tooltip
              trigger="click"
              onClick={(e) => {
                e.stopPropagation();
              }}
              autoAdjustOverflow={true}
              placement="topLeft"
              title={
                <pre style={{ width: "fit-content", maxWidth: 500 }}>
                  {text?.split(",").join("\n\r")}
                </pre>
              }
            >
              <div>
                {b1}
                <br />
                {b2}
                <br />
                {b3}
                <br />
                <h4>{rest.length ? `${rest.length} more...` : null}</h4>
              </div>
            </Tooltip>
          ) : (
            <div>
              {b1}
              <br />
              {b2}
              <br />
              {b3}
            </div>
          )}
        </TableCell>
      );
    },
  },
  {
    title: "",
    dataIndex: "Outcomes",
    render: (text) => {
      const outcomes = text?.split(",") || [];
      const [o1, o2, o3, ...rest] = outcomes;

      return (
        <TableCell>
          <h3>Outcome</h3>
          {rest.length > 0 ? (
            <Tooltip
              trigger="click"
              onClick={(e) => {
                e.stopPropagation();
              }}
              autoAdjustOverflow={true}
              placement="topLeft"
              title={
                <pre style={{ width: "fit-content", maxWidth: 500 }}>
                  {text?.split(",").join("\n\r")}
                </pre>
              }
            >
              <div>
                {o1}
                <br />
                {o2}
                <br />
                {o3}
                <br />
                <h4>{rest.length ? `${rest.length} more...` : null}</h4>
              </div>
            </Tooltip>
          ) : (
            <div>
              {o1}
              <br />
              {o2}
              <br />
              {o3}
            </div>
          )}
        </TableCell>
      );
    },
  },
  {
    title: "",
    dataIndex: "AdverseEvents",
    render: (text, _, ndx) => {
      const events = text?.split(",") || [];
      const [e1, e2, e3, ...rest] = events;

      return (
        <TableCell>
          <h3>Adverse Events</h3>
          {rest.length > 0 ? (
            <Tooltip
              trigger="click"
              onClick={(e) => {
                e.stopPropagation();
              }}
              autoAdjustOverflow={true}
              placement="topLeft"
              title={
                <pre style={{ width: "fit-content", maxWidth: 500 }}>
                  {text?.split(",").join("\n\r")}
                </pre>
              }
            >
              <div>
                {e1}
                <br />
                {e2}
                <br />
                {e3}
                <br />
                <h4>{rest.length ? `${rest.length} more...` : null}</h4>
              </div>
            </Tooltip>
          ) : (
            <div>
              {e1}
              <br />
              {e2}
              <br />
              {e3}
            </div>
          )}
        </TableCell>
      );
    },
  },
  {
    title: "",
    dataIndex: "",
    render: (record) => (
      <TableCell>
        <h3>View patient journey</h3>

        <img
          src={patientHistoryIcon}
          alt="View patient journey"
          width="200"
          onClick={(e) => {
            e.stopPropagation();
            showPreview(record.id);
          }}
        />
      </TableCell>
    ),
  },
];

const columns = [
  { title: "Adverse events", dataIndex: "adverse_events" },
  { title: "Biomarkers", dataIndex: "biomarkers" },
  { title: "Diagnosis", dataIndex: "diagnosis" },
  { title: "Main cancer", dataIndex: "main_cancer" },
  { title: "Main treatment", dataIndex: "main_treatment" },
  { title: "Metastastic", dataIndex: "metastastic" },
  { title: "Mutations", dataIndex: "mutations" },
  { title: "Outcome", dataIndex: "outcome" },
  {
    title: "Previous treatments",
    dataIndex: "previous_treatments",
    render: (data) => (
      <div style={{ whiteSpace: "pre-line" }}>{data?.join("\n \r")}</div>
    ),
  },
];

const searchCols = (selectedKeys, showPreview, customCols) => {
  if (!customCols)
    return [
      ...columns,
      {
        title: "Patient timeline",
        dataIndex: "patientTmlne",
        render: (_, record) => {
          return (
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                showPreview(record.id);
              }}
            >
              View
            </Button>
          );
        },
      },
      {
        title: "Purchase",
        dataIndex: "purchase",
        render: (x, y, z) => {
          return <Checkbox checked={selectedKeys.includes(z)} />;
        },
      },
    ];
  else {
    const colsKeys = Object.keys(customCols);
    const colsTitles = colsKeys.map((words) => {
      return words
        .split("_")
        .map(([fl, ...rest]) => {
          return fl.toUpperCase() + rest.join("");
        })
        .join("");
    });

    return [
      ...colsKeys.map((k, ndx) => ({
        title: colsTitles[ndx],
        dataIndex: k,
        key: k,
        ellipsis: {
          showTitle: false,
        },
        render: (txt) => (
          <Tooltip
            trigger="click"
            onClick={(e) => {
              e.stopPropagation();
            }}
            placement="topLeft"
            title={<pre>{txt?.split(",").join("\n\r")}</pre>}
          >
            {txt}
          </Tooltip>
        ),
      })),
      {
        title: "Patient timeline",
        dataIndex: "patientTmlne",
        render: (_, record) => {
          return (
            <Button
              type="primary"
              onClick={(e) => {
                e.stopPropagation();
                showPreview(record.id);
              }}
            >
              View
            </Button>
          );
        },
        width: 100,
      },
      {
        title: "Purchase",
        dataIndex: "purchase",
        render: (x, y, z) => {
          return <Checkbox checked={selectedKeys.includes(z)} />;
        },
        width: 100,
      },
    ];
  }
};

export const cartContentCols = (customCols) => {
  const colsKeys = Object.keys(customCols);
  const colsTitles = colsKeys.map((words) => {
    return words
      .split("_")
      .map(([fl, ...rest]) => {
        return fl.toUpperCase() + rest.join("");
      })
      .join("");
  });

  return [
    ...colsKeys.map((k, ndx) => ({
      title: colsTitles[ndx],
      dataIndex: k,
      key: k,
      ellipsis: {
        showTitle: false,
      },
      render: (txt) => (
        <Tooltip placement="topLeft" title={txt}>
          trigger="click" onClick=
          {(e) => {
            e.stopPropagation();
          }}
          {txt}
        </Tooltip>
      ),
    })),
    // {
    //   title: "Purchase",
    //   dataIndex: "purchase",
    //   render: (x, y, z) => {
    //     return <Button type="danger" />;
    //   },
    // },
  ];
};

export const cartColumns = (showPreview, removeFromCart) => {
  const cols = newColumns([], showPreview);
  return [
    {
      title: "",
      render: (record) => (
        <img
          src={trash}
          onClick={() => removeFromCart(record.id)}
          alt="remove from cart"
          style={{
            width: 15,
            cursor: "pointer",
          }}
        />
      ),
    },
    ...cols.slice(1),
  ];
};

export const purchaseHistoryCols = columns;

export default searchCols;

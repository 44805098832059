import axios from "axios";

import baseUrl from "./baseUrl";

const endpoints = {
  adverse_events: "/search/autocomplete/adverse_events",
  biomarkers: "/search/autocomplete/biomarkers",
  diagnosis: "/search/autocomplete/diagnosis",
  mutations: "/search/autocomplete/mutations",
  outcomes: "/search/autocomplete/outcomes",
  treatments: "/search/autocomplete/treatments",
  metastastic: "/search/enums/metastastic",
  main_cancer: "/search/enums/main_cancer",
};

export const getDropdownValues = () => {
  const token = localStorage.getItem("token");

  return axios({
    method: "get",
    url: baseUrl + "/search/enums",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const autocomplete = (field, str, main_cancer) => {
  const query = `?query=${str}`;
  const token = localStorage.getItem("token");

  return axios({
    method: "get",
    url: baseUrl + endpoints[field] + query + "&main_cancer=" + main_cancer,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

import { AppWrapper } from "../../components/shared/Elements";
import { Alert, Button, Modal, notification, Table } from "antd";
import PreviewCaseReport from "./PreviewCaseReport";
import {
  searchResults,
  addToShoppingCart,
  getPreview,
} from "../../helpers/api";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import columns, { newColumns } from "../../helpers/tableColumns";
import { SearchResultWrapper } from "./style";
import Gantt from "./Gantt";

const SearchResult = (props) => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    _total_count: 636,
  });
  const [loading, setLoading] = useState(false);
  const [loadingContent, setLoadingContent] = useState(true);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [modalId, setModalId] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const email = localStorage.getItem("email");

  const getData = (pageNo) => {
    const filters = location.state.formData;
    return searchResults(filters, pageNo)
      .then(({ data }) => {
        setLoadingContent(false);
        setData(data?.case_reports || []);
        setPagination(data._metadata);
      })
      .catch((e) => {
        setLoadingContent(false);
        setData([]);
        setError(e?.response?.data?.detail?.slice(0, 120) + " ... ");
      });
  };

  useEffect(() => {
    getData("page_no=1&no_per_page=20");
  }, [location.state.formData]);

  const showPreview = (id) => {
    setModalId(id);
    setModalVisible(true);
  };

  const onClose = () => {
    setModalId(null);
    setModalVisible(false);
  };

  const selectRow = (record) => {
    const selectedRowKeys = [...selectedKeys];
    if (selectedRowKeys.includes(record.id)) {
      selectedRowKeys.splice(selectedRowKeys.indexOf(record.id), 1);
      setSelectedKeys(selectedRowKeys);
    } else {
      setSelectedKeys([...selectedRowKeys, record.id]);
    }
  };

  const addToCart = () => {
    const projectId = localStorage.getItem("selectedProject");
    setLoading(true);
    const ids = selectedKeys.map((ndx) => data[ndx].id);
    addToShoppingCart(projectId, ids)
      .then((res) => {
        setLoading(false);
        props.updateHeader();
        notification.open({
          message: (
            <span>
              Items added to cart. <a href="/cart">Click here</a> to view
            </span>
          ),
          description:
            "Thank you for preparing your purchase with OpenCase.AI.  We will contact you shortly",
          duration: 5,
          type: "success",
        });
      })
      .catch((e) => {
        setLoading(false);
        notification.open({
          message: <span>Oops, something went wrong</span>,
          duration: 3,
          type: "error",
        });
      });
  };

  const handlePageChange = (pageNo) => {
    setLoadingContent(true);
    getData("page_no=" + pageNo + "&no_per_page=20").then(() => {
      setLoadingContent(false);
      setCurrentPage(pageNo);
    });
  };

  return (
    <AppWrapper style={{ paddingRight: 0 }}>
      <SearchResultWrapper id="searchResults">
        {error ? <Alert type="error" message={error} closable /> : null}
        {selectedKeys.length ? (
          <Button
            loading={loading}
            type="primary"
            style={{ float: "right", marginBottom: 20 }}
            onClick={addToCart}
          >
            Add to shopping cart
          </Button>
        ) : null}
        <Table
          style={{
            height: "calc(100vh - 160px)",
          }}
          loading={loadingContent}
          // columns={columns(selectedKeys, showPreview, data[0])}
          columns={newColumns(selectedKeys, showPreview)}
          dataSource={data}
          pagination={{
            pageSize: 20,
            total: pagination?._total_count,
            onChange: (a, b, c) => handlePageChange(a, b, c),
          }}
          onRow={(record, ndx) => ({
            onClick: () => {
              selectRow({ id: ndx });
              setModalId(record.id);
            },
          })}
          rowClassName={(record, ndx) =>
            `resultRow ${ndx > 4 || currentPage > 1}`
          }
          showHeader={false}
          rowKey={(e) => e.id}
        />

        <Modal
          visible={modalVisible}
          closable={true}
          onCancel={() => onClose()}
          style={{ background: "#fff", marginTop: -50 }}
          footer={false}
          width={1400}
          destroyOnClose={true}
        >
          <PreviewCaseReport reportId={modalId} />
        </Modal>
      </SearchResultWrapper>
    </AppWrapper>
  );
};

export default SearchResult;

import styled from "styled-components";

export const Header = styled.h1`
  color: #3a506b;
  text-shadow: 0 3px 10px rgb(255 255 255 / 30%);
  text-align: center;
  font-size: 45px;
  margin: 60px auto;
`;

export const LoginForm = styled.form`
  width: 600px;
  height: 305px;
  padding: 20px;
  margin: auto;
  margin-top: 150px;
  position: relative;
  background: #e0dede;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
`;

export const InputGroup = styled.div`
  padding: 15px 20px;
  margin-bottom: 10px;

  label {
    font-size: 18px;
    width: 180px;
    display: inline-block;
  }

  input {
    width: 270px;
    border: none;
    padding: 5px;
    border-radius: 3px;
  }
`;

export const SubmitButton = styled.div`
  float: right;

  button {
    padding: 0 25px;
    height: 45px;
    color: #000;
    box-shadow: none;
    font-size: 16px;
    cursor: pointer;
  }
`;

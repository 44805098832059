import styled from "styled-components";

export const Wrapper = styled.div`
  background: white;
  width: 25%;
  max-width: 500px;
  padding: 30px;
  border-radius: 5px;
  height: 700px;
  margin: auto;

  h1 {
    height: 100px;
    font-size: 23px;
  }

  div {
    line-height: 25px;
  }

  p {
    font-weight: bold;
    margin-top: 10px;
  }
  
  span {
    font-weight: bold;
    margin-top: 30px;
    display: block;
  }
`;
